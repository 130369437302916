import Column from "@amzn/meridian/column";
import React, {useReducer, useState, useRef} from "react";
import Select, {SelectOption} from "@amzn/meridian/select"
import {PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS} from "../../router/ProblemSolveExecutionRouter";
import Text from "@amzn/meridian/text";
import {FormattedMessage} from "react-intl";
import Box from "@amzn/meridian/box";
import Row from "@amzn/meridian/row";
import {SALUtils, TextExtractionUtils} from "@amzn/dolphin-image-processing-utils";
import {Camera} from 'react-camera-pro';
import salImage from "../../assets/images/ic_sal.png";
import {
    getScannedScannableIds,
    getShipmentDetail
} from "../../dataStore/PSExecutionDataStore";
import Button from "@amzn/meridian/button";
import {
    SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE,
    SalcolorAuditInitialState,
    SalColorAuditReducer
} from "../../reducers/SalColorAuditReducer";
import InProgress from "../common/InProgress";
import {COGNITO_POOL_IDS, SAL_COLOR_MAP} from "../../constants/Constants";
import {Logger} from "@amzn/dolphin-web-framework";
import Alert from "@amzn/meridian/alert";
import {SoundManager} from "../../audio/SoundManager";
import {
    getActionListFromDataStore,
    getAdditionalProperties, saveActionList, saveAdditionalProperties
} from "../../dataStore/LocationStateDataStore";

const SalColorAudit = (props) => {
    const[{loading, loadNextComponent}, dispatch]= useReducer(SalColorAuditReducer, SalcolorAuditInitialState)

    if(!props.location.state){
        props.location.state = {
            properties: {
                actionList: getActionListFromDataStore(),
            },
            additionalProperties: getAdditionalProperties(),
        }
    }

    const packageId = getScannedScannableIds();
    const actionList = props.location.state.properties.actionList;
    const additionalProperties = props.location.state.additionalProperties;
    const shipmentDetail = getShipmentDetail();
    const salColorHexCode = shipmentDetail.length > 0 ? shipmentDetail[0].salColor : ""
    saveActionList(actionList)
    saveAdditionalProperties(additionalProperties)

    const [mismatch, setMismatch] = useState(false)
    const [selectedValue, setSelectedValue] = useState("")
    const [processing, setProcessing] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const camera = useRef(null);
    const image = useRef(null);
    const imgRef = useRef(null);
    const notification = useRef({id: "", defaultMessage: ""});
    const blockCoordinates = useRef({});
    const imageProcessingFailureCount = useRef(0);
    const textExtractionUtils = new TextExtractionUtils("eu-west-1", COGNITO_POOL_IDS["Prod-IN-eu-west-1"]);
    const salUtils = new SALUtils();

    const updateNextComponentDetails = () => {
        props.history.push({
            pathname: PROBLEM_SOLVE_EXECUTION_ROUTE_PATHS.PROBLEM_ACTION,
            state: {
                properties: {
                    actionList: actionList
                },
                additionalProperties: additionalProperties
            }
        });
    }

    if (loadNextComponent) {
        updateNextComponentDetails();
    }

    const overrideSalColor = (color) => {
        dispatch({
            type: SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE.OVERRIDE_SAL_COLOR,
            dispatcher: dispatch,
            packageId: packageId,
            newColor: color,
            salMissing: false
        })
    }

    const handleMissingSal = () => {
        dispatch({
            type: SAL_COLOR_AUDIT_REDUCERS_ACTION_TYPE.OVERRIDE_SAL_COLOR,
            dispatcher: dispatch,
            packageId: packageId,
            salMissing: true
        })
    }

    const confirmSalColor = () => {
        if (selectedValue === salColorHexCode) {
            updateNextComponentDetails();
        } else {
            setMismatch(true);
        }
    }

    const takePicture = () => {
        setShowNotification(false);
        setProcessing(true);
        image.current = camera.current.takePhoto();
        textExtractionUtils.detectTextFromDocument(image.current)
            .then(imageAnalysedData => {
                Logger.log.warn("Result from Textract " + JSON.stringify(imageAnalysedData));
                if (isExpectedSAL(imageAnalysedData)) {
                    compareSalColor();
                } else {
                    Logger.log.warn("Expected sortzone " + shipmentDetail[0].sortZone + " not found for " + shipmentDetail[0].scannableId);
                    imageProcessingFailureCount.current = imageProcessingFailureCount.current + 1;
                    if (imageProcessingFailureCount.current === 2) {
                        overrideSalColor("");
                    } else {
                        displayErrorNotification({id:"incorrect_sal_image", defaultMessage: "Incorrect/unclear SAL image"});
                    }
                }
                image.current = null;
                setProcessing(false);
            })
            .catch(exception => {
                Logger.log.warn("Exception from Textract " + JSON.stringify(exception));
                imageProcessingFailureCount.current = imageProcessingFailureCount.current + 1;
                image.current = null;
                setProcessing(false);
                if (imageProcessingFailureCount.current === 2) {
                    overrideSalColor("");
                } else {
                    displayErrorNotification({id:"incorrect_sal_image", defaultMessage: "Incorrect/unclear SAL image"});
                }
            });
    }

    const isExpectedSAL = (imageAnalysedData) => {
        let isExpectedSal = false;
        imageAnalysedData.Blocks.every((block) => {
            if (block.BlockType === 'LINE' || block.BlockType === 'WORD') {
                const refinedText = block.Text.toLowerCase().replace(/[^A-Za-z0-9]/g, '');
                const refinedTrackingId = shipmentDetail[0]?.scannableId ? shipmentDetail[0].scannableId.toLowerCase().slice(-5) : "amzl";
                const sortZone = shipmentDetail[0]?.sortZone ? shipmentDetail[0].sortZone.toLowerCase().slice(1) : "sideline";
                const refinedSortZone = sortZone.replace(/[^A-Za-z0-9]/g, '');
                if (refinedText.includes(refinedSortZone) || refinedText.includes(refinedTrackingId) || isErrorCodePresent(refinedText, block.text)) {
                    isExpectedSal = true;
                    blockCoordinates.current = textExtractionUtils.extractCoordinatesForBlock(block, imgRef.current);
                    return false;//breaks loop, nothing else
                }
            }
            return true;//continue loop
        })
        return isExpectedSal;
    }

    const isErrorCodePresent = (refinedText, text) => {
        return (refinedText.includes("amzl") || refinedText.includes("sideline") || refinedText.includes("fcret")
            || refinedText.includes("cplan") || refinedText.includes("hold") || refinedText.includes("trkid")
            || refinedText.includes("cosmos") || refinedText.includes("relabel") || text === "NA" || text.includes("Cycle"));
    }

    const compareSalColor = () => {
        const extractedSalColorHex = salUtils.extractSalColorFromImage(imgRef.current, blockCoordinates.current, 2, 1);
        if (extractedSalColorHex === selectedValue) {
            overrideSalColor(selectedValue);
        } else {
            Logger.log.warn("Expected salColor " + selectedValue + " but found " + extractedSalColorHex);
            imageProcessingFailureCount.current = imageProcessingFailureCount.current + 1;
            if (imageProcessingFailureCount.current === 2) {
                overrideSalColor("");
            } else {
                displayErrorNotification({id:"picture_does_not_match_selected_color", defaultMessage: "Picture does not match selected color. Use the Back button to change selection"});
            }
        }
    }

    const displayErrorNotification = (notificationMessageId) => {
        notification.current = notificationMessageId;
        setShowNotification(true);
        SoundManager.playErrorSound();
    }

    const divStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

    const imageStyle = {
        flexBasis: '40%',
        order: '2'
    }

    const textStyle = {
        color: '#FF0000',
        fontSize: '14px'
    }

    return (
        <Column height="100%" spacing="none" spacingInset="none">
            {(processing || loading) && <InProgress/>}
            {showNotification && <Row width="100%" widths="fill" spacingInset="medium medium none medium">
                <Alert type="error" size="medium" onClose={() => setShowNotification(false)}>
                    <FormattedMessage id={notification.current.id} defaultMessage={notification.current.defaultMessage}/>
                </Alert>
            </Row>}
            {!mismatch && <Box spacingInset="medium medium small medium">
                <Text type="h500" alignment="center"><FormattedMessage id={"sal_color_audit"}/></Text>
            </Box>}
            <Row spacingInset="small" widths="fill">
                <Box spacingInset="none none none small" type="outline">
                    <Text type="b500" alignment="left">{packageId}</Text>
                    <Text type="b200" alignment="left"><FormattedMessage id={"package_id"}/></Text>
                </Box>
            </Row>
            {!mismatch && <div>
                <Row widths="fill" spacingInset="small none none none">
                    <Text type="h300" alignment="center"><FormattedMessage id="Select SAL Color seen on Package"/></Text>
                </Row>
                <Row spacing="medium" spacingInset="small xlarge large xlarge" alignmentHorizontal={"center"}>
                    <Select value={selectedValue} onChange={setSelectedValue} placeholder="Select SAL Color" width={300}>
                        {Object.entries(SAL_COLOR_MAP).map(([hexCode, colorName]) => (
                            <SelectOption value={hexCode} label={colorName}>
                                {() => (
                                    <div  style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <Text type="h100"><FormattedMessage id={colorName}/></Text>
                                        <Box backgroundColor={hexCode} spacingInset="medium large small small" type={"outline"}/>
                                    </div>
                                )}
                            </SelectOption>))
                        })}
                        <SelectOption value={handleMissingSal} label="SAL Missing on package">
                            {() => (<Row><Text type="h100"><FormattedMessage id="SAL Missing on package"/></Text></Row>)}
                        </SelectOption>
                    </Select>
                </Row>
            </div>}

            {!mismatch && selectedValue && <div>
                <Row spacing="medium" spacingInset="none large large large" alignmentHorizontal={"center"}>
                    <Box backgroundColor={selectedValue} spacingInset="xlarge" type={"outline"} width="66%"></Box>
                </Row>
                <Row width="100%" widths={["grid-10", "grid-10"]} wrap="down" alignmentHorizontal={"center"}>
                    <Button type="secondary" onClick={confirmSalColor}><FormattedMessage id="Confirm"/></Button>
                </Row>
            </div>}

            {mismatch && <div>
                <Row spacing="small" spacingInset="none none none small" alignmentHorizontal={"center"}>
                    <div class="container" style={divStyle}>
                        <div class="image" style={imageStyle}><img width="100%" src={salImage} alt=""/></div>
                        <div class="text" style={textStyle}>
                            <FormattedMessage id="picture_audit_message"
                               defaultMessage="SAL Color audit via picture is required. SAL color selected in PS does not match SAL color recorded at induct."/>
                        </div>
                    </div>
                </Row>
                <Row alignmentHorizontal="center">
                    <Text type="b200"><FormattedMessage id="sal_capture_instruction" defaultMessage="Fit SAL in the camera frame and take a clear picture"/></Text>
                </Row>
                {!image.current ? <Camera ref={camera} facingMode='environment' aspectRatio={16 / 9}/>
                    : <img src={image.current} alt={''} crossOrigin='anonymous' ref={imgRef} width={480} height={270}/>}
                <Row width='100%' widths={['grid-10', 'grid-10']} wrap='down' spacingInset='medium' spacing="large" alignmentHorizontal={'center'}>
                    {!image.current ? <Button type='secondary' onClick={takePicture}><FormattedMessage id='take_picture' defaultMessage={"Take Picture"}/></Button> : null}
                </Row>
            </div>}
        </Column>
    )
}
export default SalColorAudit;